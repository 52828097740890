import { checkStatus, handleError } from '../../utils/api';
import { getAuthTokenAsync } from '../../utils/authToken';
import { ValidateUser } from '../../interfaces/login';
import {
  USERNAME_OR_EMAIL_REQUIRED,
  INCORRECT_USERNAME_OR_EMAIL,
  UNEXPECTED_ERROR,
} from '../../constants/errors';
import apiFetch from '../core/apiFetch';

interface AccountExistenceFormProps {
  usernameOrEmail: string;
}

interface Response {
  users?: ValidateUser[];
  error?: {
    code: string;
    message: string[];
  };
}

const accountExistenceAsync = async ({
  usernameOrEmail,
}: AccountExistenceFormProps): Promise<Response> => {
  try {
    const q = `validate_user[username_or_email]=${encodeURIComponent(
      usernameOrEmail
    )}`;
    const url = `api/v2/validate_user/account_existence?${q}`;
    const authToken = await getAuthTokenAsync();
    const resp = await apiFetch(authToken, url, { method: 'GET' });
    await checkStatus(resp);
    return await resp.json();
  } catch (error) {
    const { message, response } = error;

    if (response?.status === 400) {
      const payload = await response.json();
      return {
        error: {
          code: USERNAME_OR_EMAIL_REQUIRED,
          message: payload.errors || [],
        },
      };
    }

    if (response?.status === 401) {
      const payload = await response.json();
      return {
        error: {
          code: INCORRECT_USERNAME_OR_EMAIL,
          message: payload.errors || [],
        },
      };
    }

    handleError('unexpected status - account existence', error);
    return {
      error: {
        code: UNEXPECTED_ERROR,
        message: [message],
      },
    };
  }
};

export default accountExistenceAsync;
