import React, { FunctionComponent, useEffect, useRef } from 'react';
import base64 from 'base-64';

import { Auth } from '../../interfaces/auth';
import { dispatchMobileRegistrationSuccess } from '../../lib/mobile';
import { useCustomizable } from '../../components/app/CustomizableProvider';

interface Props {
  auth: Auth;
  redirectTo?: string;
}

const validateCustomRedirect = (
  redirectTo: string | undefined,
  isCustom: boolean,
  redirectKey: string | undefined
): string | undefined => {
  if (isCustom) {
    if (redirectKey && redirectTo !== redirectKey) {
      /**
       * @description
       * If redirectKey is no present even if isCustom is true,
       * return redirectKey value saved from the initial custom url
       */
      return redirectKey;
    }
  }

  return redirectTo;
};

const AutoLoginContainer: FunctionComponent<Props> = ({
  auth,
  redirectTo,
}: Props) => {
  const formRef = useRef<HTMLFormElement>(null);
  const { isCustomizable, params: customizableParams } = useCustomizable();

  useEffect(() => {
    const { userId, sso, jwt } = auth;
    const { idpId, statusToken } = sso;

    /**
     * @description
     * Apply validate for welldoc params.
     * We can apply this validate for any custom param
     */
    const redirectUrl = validateCustomRedirect(
      redirectTo,
      isCustomizable,
      customizableParams?.redirect_to
    );

    const params = {
      user_id: userId, // eslint-disable-line @typescript-eslint/camelcase
      idp_id: idpId, // eslint-disable-line @typescript-eslint/camelcase
      status_token: statusToken, // eslint-disable-line @typescript-eslint/camelcase
      redirect_to: redirectUrl, // eslint-disable-line @typescript-eslint/camelcase
    };

    if (
      dispatchMobileRegistrationSuccess(
        {
          token: jwt,
          userId,
        },
        'registration'
      )
    ) {
      return;
    }

    const handleSubmitForm = (url: string) => {
      if (formRef.current) {
        formRef.current.action = url;
        formRef.current.submit();
      }
    };

    const url = `${
      process.env.PATIENT_URL
    }/set_cookie_and_redirect?base64_params=${base64.encode(
      JSON.stringify(params)
    )}`;

    handleSubmitForm(url);
  }, []);

  return <form ref={formRef} method="POST" />;
};

AutoLoginContainer.defaultProps = {
  redirectTo: process.env.PATIENT_URL,
};

export default AutoLoginContainer;
