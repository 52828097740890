import { ConnectionType } from '../interfaces/login';
import { PHONE } from '../constants/login';

const getResendCodeMessage = (connectionType: ConnectionType): string => {
  return connectionType === PHONE
    ? 'accountRecovery.codePhoneResent'
    : 'accountRecovery.codeResent';
};

export default getResendCodeMessage;
