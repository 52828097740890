export const HTML_ERRORS = {
  recoverMyAccount:
    "<a href='/account-recovery'><b>Recover my account.</b></a>",
  changePassword: "<a href='tel:1-800-400-6354'>(800) 400-6354</a>",
};

const lookingForDataTrack = (anchorTag: string) => {
  const [prevAnchor, nextAnchor] = anchorTag.split('<a');
  let dataTrack = '';

  if (anchorTag === HTML_ERRORS.recoverMyAccount) {
    dataTrack = JSON.stringify({
      controlName: 'recover-my-account-link',
      controlText: 'Recover-my-account',
    });
  }

  if (anchorTag === HTML_ERRORS.changePassword) {
    dataTrack = JSON.stringify({
      controlName: 'contact-changepassword-support-link',
      controlText: 'change-password',
    });
  }

  return `${prevAnchor}<a data-track-event="true" data-track=${dataTrack} ${nextAnchor}`;
};

const injectToString = (message: string, lookingFor: string) => {
  let injectedMessage = message;

  if (message.includes(lookingFor)) {
    const linkString = lookingForDataTrack(lookingFor);
    injectedMessage = message.replace(lookingFor, linkString.trim());
  }

  return injectedMessage;
};

export default injectToString;
