import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { useIntl, FormattedMessage } from 'react-intl';
import { Form, Field } from 'react-final-form';
import { InputField, Box } from 'mdlkit';
import { FormSubmitLoadingButton } from '../shared/Styles';
import { required } from '../../utils/shared/validations';
import { code } from '../../utils/login/validations';
import { normalizeNumbersOnly } from '../../utils/register/normalize';
import { AuthCodeSession } from '../../interfaces/login';

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: ${({ theme }) => theme.space.md}px;
`;

const ButtonContainer = styled(Box)<{ buttonAtEnd?: boolean }>`
  margin: auto;
  ${({ buttonAtEnd }) =>
    buttonAtEnd &&
    `
      position: absolute; 
      bottom: 0;
      left: 50%;
      transform: translate(-50%);
    `}
`;

interface Props {
  authCodeId: string;
  userId: number;
  onSubmit: (values: AuthCodeSession) => Promise<any> | void;
  buttonAtEnd?: boolean;
  onClick?: () => void;
}

const AuthCodeSessionForm: FunctionComponent<Props> = ({
  authCodeId,
  userId,
  onSubmit,
  buttonAtEnd,
  onClick,
}: Props) => {
  const { formatMessage: f } = useIntl();

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ authCodeId, userId, code: null }}
      render={({
        submitting,
        handleSubmit,
        pristine,
        hasValidationErrors,
        hasSubmitErrors,
        dirtySinceLastSubmit,
      }) => (
        <StyledForm onSubmit={handleSubmit}>
          <Field
            id="authCodeId"
            name="authCodeId"
            type="hidden"
            validate={required}
            component="input"
          />
          <Field
            id="userId"
            name="userId"
            type="hidden"
            validate={required}
            component="input"
          />
          <Field
            id="code"
            label={f({ id: 'accountRecovery.authenticationCode' })}
            placeholder={f({ id: 'accountRecovery.5digitCode' })}
            name="code"
            validate={code}
            component={InputField}
            inputMode="numeric"
            maxLength={5}
            parse={normalizeNumbersOnly}
          />
          <ButtonContainer buttonAtEnd={buttonAtEnd}>
            <FormSubmitLoadingButton
              id="verifyCodeBtn"
              type="submit"
              size="large"
              disabled={
                submitting ||
                pristine ||
                hasValidationErrors ||
                (hasSubmitErrors && !dirtySinceLastSubmit)
              }
              onClick={onClick}
              isLoading={submitting}
            >
              <FormattedMessage id="accountRecovery.verifyButton" />
            </FormSubmitLoadingButton>
          </ButtonContainer>
        </StyledForm>
      )}
    />
  );
};

export default AuthCodeSessionForm;
