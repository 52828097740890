import { checkStatus, handleError } from '../../utils/api';
import { parseJwt } from '../../utils/authToken';
import {
  numberOfParamsFromCurrentHref,
  redirectToValue,
} from '../../utils/login/validations';
import { SsoToken } from '../../interfaces/auth';
import {
  EMAIL_REQUIRED,
  INCORRECT_USER_OR_PASSWORD,
  UNEXPECTED_ERROR,
  LOCKED_USER,
  MISSING_PROFILE,
  ERROR_403_BLOCKED,
} from '../../constants/errors';
import nextFetch from '../core/nextFetch';

interface LoginFormProps {
  email: string;
  password: string;
}

export interface ErrorResponse {
  error: {
    code: string;
    message: string[];
    redirectUrl: string;
  };
}

export interface SuccessResponse {
  jwt: string;
  userId: number;
  sso: SsoToken;
  redirectUrl: string;
}

const loginAsync = async ({
  email,
  password,
}: LoginFormProps): Promise<SuccessResponse | ErrorResponse> => {
  try {
    const numberOfParams = numberOfParamsFromCurrentHref();
    const redirect_path =
      numberOfParams > 1
        ? redirectToValue()
        : window.location.search.split('=')[1] || '';

    const resp = await nextFetch('/api/login', {
      method: 'POST',
      body: JSON.stringify({
        username: email,
        password,
        redirect_path,
      }),
    });

    await checkStatus(resp);

    const jsonResp = await resp.json();
    const parsedJwt = parseJwt(jsonResp.jwt);

    return {
      jwt: jsonResp.jwt,
      userId: parsedJwt.userid,
      sso: {
        idpId: jsonResp.sso.idp_id,
        statusToken: jsonResp.sso.status_token,
      },
      redirectUrl: jsonResp.redirect_url,
    };
  } catch (error) {
    const { message, response } = error;

    if (response.status === 400) {
      const payload = await response.json();
      return {
        error: {
          code: EMAIL_REQUIRED,
          message: payload.errors || [],
          redirectUrl: '',
        },
      };
    }

    if (response.status === 401) {
      const payload = await response.json();
      const isLocked = Boolean(
        payload.errors.filter((err) => err.match(/account has been locked/i))
          .length
      );
      if (isLocked) {
        return {
          error: {
            code: LOCKED_USER,
            message: payload.errors || [],
            redirectUrl: '',
          },
        };
      }

      const isProfileMissing = Boolean(
        payload.errors.filter((err) => err.match(/missing profile data/i))
          .length
      );
      if (isProfileMissing) {
        return {
          error: {
            code: MISSING_PROFILE,
            message: payload.errors || [],
            redirectUrl: payload.redirect_url,
          },
        };
      }

      return {
        error: {
          code: INCORRECT_USER_OR_PASSWORD,
          message: payload.errors || [],
          redirectUrl: '',
        },
      };
    }

    if (response.status === 403) {
      const payload = await response.json();

      if (payload.errorCode === 'BLOCK0001') {
        return {
          error: {
            code: ERROR_403_BLOCKED,
            message: payload.errorId || '',
            redirectUrl: '/403',
          },
        };
      }
    }

    handleError('unexpected status - login error', error);
    return {
      error: {
        code: UNEXPECTED_ERROR,
        message: [message],
        redirectUrl: '',
      },
    };
  }
};

export default loginAsync;
