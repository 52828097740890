import apiFetch from '../core/apiFetch';
import { checkStatus, handleError } from '../../utils/api';
import {
  AuthCode,
  AuthCodeResponse,
  AuthCodeError,
} from '../../interfaces/login';
import {
  AUTH_CODE_ERROR,
  INVALID_PHONE,
  MULTIPLE_PHONE_NUMBERS,
  TOO_MANY_REQUESTS,
} from '../../constants/errors';
import { getAuthTokenAsync } from '../../utils/authToken';

const createAuthCodeAsync = async ({
  userId,
  token,
  connection,
}: AuthCode): Promise<{
  error?: AuthCodeError;
  authCode?: AuthCodeResponse;
}> => {
  const url = `api/v1/auth_code`;

  try {
    const authToken = await getAuthTokenAsync();

    const response = await apiFetch(authToken, url, {
      method: 'POST',
      body: JSON.stringify({
        auth_code: { user_id: userId, token, connection },
      }),
      headers: {
        'X-Forwarded-For-MDL-NEXT-Token': authToken,
      },
    });

    await checkStatus(response);
    const { auth_code: authCodeResponse } = await response.json();

    return {
      authCode: {
        id: authCodeResponse.id,
        connection: authCodeResponse.connection,
        user: authCodeResponse.user,
      },
    };
  } catch (error) {
    const { response } = error;

    if (response && response.status === 409) {
      return { error: { code: INVALID_PHONE } };
    }

    if (response && response.status === 422) {
      const payload = await response.json();

      const {
        errors: { accounts_conflict },
      } = payload;

      if (accounts_conflict) {
        const users = accounts_conflict[0].users.map(
          ({ token: perishable_token, phone_last_two_digits }) => {
            return { token: perishable_token, phone: phone_last_two_digits };
          }
        );
        return { error: { code: MULTIPLE_PHONE_NUMBERS, payload: users } };
      }
    }

    if (response && response.status === 429) {
      return { error: { code: TOO_MANY_REQUESTS } };
    }

    handleError('unexpected status - create passwordless session', error);
    return { error: { code: AUTH_CODE_ERROR } };
  }
};

export default createAuthCodeAsync;
